type FormItem = {
	value: string;
	placeholder: string;
	mask?: string;
	error: boolean;
	errorMessage?: string;
};

export const useFormState = (target: string | null = null) => ({
	formModel: useState<{ name: FormItem; email: FormItem; phone: FormItem }>(
		target?.length ? `formModel__${target}` : 'formModel',
		() => ({
			name: {
				value: '',
				placeholder: 'Ваше имя',
				mask: undefined,
				error: false,
				errorMessage: 'Введите имя',
			},
			email: {
				value: '',
				placeholder: 'E-mail',
				mask: undefined,
				error: false,
				errorMessage: 'Введите E-mail',
			},
			phone: {
				value: '',
				placeholder: 'Телефон',
				mask: '+7 (###) ###-##-##',
				error: false,
				errorMessage: 'Введите телефон',
			},
		})
	),
});

export const useForm = (target: string | null = null) => {
	const { formModel } = useFormState(target);

	return {
		formModel,
	};
};
